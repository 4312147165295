import React from 'react';

function RapportMap({rapports}) {
	return rapports.map(rapport =>{
		return(
			<div className={"work"}>
				<img src={rapport.img} alt="rapport-image" className={"work-img"}/>
				<div className="work-info">
					<h3>{rapport.title}</h3>
					<h4>{rapport.subtitle}</h4>
					<p>{rapport.content}</p>
					<div className={"liens"}>
						<a href={rapport.lien_rapport} type="button" className="rapport_btn" target="_blank">Notre rapport</a>
						<a href={rapport.lien_proposition} type="button" className="rapport_btn" target="_blank">{rapport.lien_label}</a>
					</div>
				</div>
			</div>
		)
	})
}

export default RapportMap;
