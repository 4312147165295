import React from 'react';
import "./about.css";

function About() {
	return (
		<section id="about">
			<div className="container">
				<h1 className="section_title">Qui sommes-<span>nous</span> ?</h1>
				<div className="content">
					<img src="https://yhstorage.blob.core.windows.net/website/CA/bryan.jpg" alt="photo-président"
					     className="president-image"/>
					<div className="text">
						<h2>Bryan <span>SIMON</span></h2>
						<h3><span>Président</span><span>Fondateur</span></h3>
						<p>Youth’s Horizon a été créée en juillet 2020 à la suite d’un constat très simple : j'ai
							remarqué
							qu’il
							existait très peu de think &amp; do tanks menés par et pour la jeunesse en France. C’est
							pourquoi
							j’ai voulu fonder, avec les autres membres, Youth’s Horizon qui est donc une association
							dédiée
							aux
							questions éducatives ainsi qu’aux politiques de jeunesse. Notre objectif est simple : nous
							voulons
							faire changer les choses, en devenant une véritable force de proposition. Nous voulons
							passer à
							l’action, agir de façon tangible, en travaillant au maximum avec les pouvoirs publics, à
							toutes
							les
							échelles. Nous avons parfaitement conscience que ce n’est pas quelque chose que l’on peut
							faire
							dans
							la précipitation. Bien au contraire, nous prenons le temps d’observer notre société, le tout
							au
							travers du prisme de notre jeunesse. C’est un changement dans la durée que nous visons, en
							nous
							tournant vers les jeunes qui marcheront dans nos pas. Pour atteindre cet objectif, nous
							rédigeons
							des rapports sur des sujets variables, et essayons de mettre en place des expérimentations.
							J’espère
							pouvoir vous compter parmi nous !</p>
					</div>
				</div>

				<h1 className="section_title equipe">Notre <span>Équipe</span> !</h1>
				<div className="membres">
					<div className="membre">
						<h2>Bryan <span>S.</span></h2>
						<h3><span>Président</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/bryan.jpg"
							alt="" className="membre-img named"/>
					</div>

					<div className="membre">
						<h2>John-John <span>B.</span></h2>
						<h3><span>Dir. Cab de la Présidence</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/john.jpg" alt=""
							className="membre-img"/>
					</div>

					<div className="membre">
						<h2>Harishan <span>U.</span></h2>
						<h3><span>Directeur Général</span>
						</h3><img
						src="https://yhstorage.blob.core.windows.net/website/CA/harishan.jpg" alt=""
						className="membre-img named"/>
					</div>
					<div className="membre">
						<h2>Elsa <span>L.</span></h2>
						<h3><span>Directrice Générale Adjointe</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/elsa.jpg" alt=""
							className="membre-img"/>
					</div>
					<div className="membre">
						<h2>Juliette <span>E.</span></h2>
						<h3><span>Did. Cab de la Direction Générale</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/juliette.jpg" alt=""
							className="membre-img"/>
					</div>
					<div className="membre"><h2>Lise <span>R.</span></h2>
						<h3><span>Directrice Juridique et Financier</span></h3><img
							src="https://yhstorage.blob.core.windows.net/website/CA/lise.png" alt=""
							className="membre-img"/>
					</div>
					<div className="membre">
						<h2>Nino <span>H.</span></h2>
						<h3><span>Directeur du Parc Numérique</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/nino.jpg" alt=""
							className="membre-img"/>
					</div>
					<div className="membre"><h2>Kérya <span>L.</span></h2>
						<h3><span>Directrice Administrative</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/kerya.jpg" alt=""
							className="membre-img"/>
					</div>
					<div className="membre">
						<h2>Thomas <span>J.</span></h2>
						<h3><span>Conseiller Spécial à la Présidence</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/thomas.jpg" alt=""
							className="membre-img"/>
					</div>
					<div className="membre">
						<h2>Lila <span>N.</span></h2>
						<h3><span>Directrice des Études</span></h3>
						<img
							src="https://yhstorage.blob.core.windows.net/website/CA/lila.jpg" alt=""
							className="membre-img named"/>
					</div>
				</div>
			</div>
		</section>
	);
}

export default About;