import React from 'react';

function TravauxMap({travaux}) {
	return travaux.map((travail => {
		console.log(travail);
		return (
			<div className={"work"}>
				<img src={travail.img} alt="rapport-image" className={"work-img"}/>
				<div className="work-info">
					<h3>{travail.title}</h3>
					<h4>{travail.subtitle}</h4>
					<p>{travail.content}</p>
					<div className={"liens"}>
						<a href={travail.lien} type="button" className="rapport_btn"
						   target="_blank">{travail.lien_label}</a>
					</div>
				</div>
			</div>
		);
	}))
		;
}

export default TravauxMap;