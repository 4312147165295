import React, {useEffect, useState} from 'react';
import "./navbar.css";

function Navbar() {
	const [className, setClassName] = useState("");
	const [isActive, setIsActive] = useState("")

	useEffect(() => {
		document.addEventListener('scroll', () => {
			if (window.scrollY > 20) {
				setClassName("scrolled");
			}
			if (window.scrollY < 20) {
				setClassName("");
			}
		});
	});

	function handleClick(){
		if (isActive === ""){
			setIsActive("active")
		}else{
			setIsActive("")
		}
	}

	return (
		<div className={"navbar " + className}>
			<div className="container">
				<div className="logo" onclick={() => window.location.href = "#home"}></div>
				<button className={`menu-toggle ${isActive}`} onClick={handleClick}>
					<span></span>
					<span></span>
					<span></span>
				</button>
				<div className={`menu ${isActive}`}>
					<a href="#projects">Nos projets</a>
					<a href="#about">Qui sommes-nous ?</a>
					<a href="#contact">Contactez-nous</a>
				</div>
			</div>
		</div>
	);
}

export default Navbar;