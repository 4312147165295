import React from 'react';
import "./footer.css"

function Footer(props) {
	return (
		<footer>
			<div className="container">
				<div className="copyright">
					© 2021 <span>Youth's Horizon</span> | Tout droits réservés.
				</div>
				<div className="social">
					<a href="https://twitter.com/YouthsHorizon" className="fab fa-twitter"></a>
					<a href="https://www.instagram.com/youthshorizon/" className="fab fa-instagram"></a>
					<a href="https://linkedin.com/company/youthshorizon" className="fab fa-linkedin-in"></a>
					<a href="https://www.facebook.com/youthshorizon/" className="fab fa-facebook"></a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;