import React, {useEffect, useState} from 'react';
import {travauxService} from "../../../services/travauxService";
import TravauxMap from "./travauxMap";
import "./travaux.css";

function Travaux() {
	const [travaux, setTravaux] = useState([]);

	useEffect(async () => {
		const travaux = await travauxService.fetch();
		setTravaux(travaux.data);
	}, []);

	return (
		<div><h1 className="section_title section_title--black">Nos <span>Travaux</span></h1>
			<div className="all-works">
				<TravauxMap travaux={travaux}></TravauxMap>
			</div>
		</div>
	);
}

export default Travaux;
