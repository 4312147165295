import "./app.css";
import Navbar from "./Component/navbar/navbar";
import Home from "./Component/home/home";
import Projects from "./Component/projects/projects";
import About from "./Component/about/about";
import Contact from "./Component/contact/contact";
import Footer from "./Component/footer/footer";

function App() {
	return (
		<>
			<Navbar/>
			<Home/>
			<Projects/>
			<About/>
			<Contact/>
			<Footer/>
		</>
	);
}

export default App;
