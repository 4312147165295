import React from 'react';
import "./home.css"

function Home(props) {
	return (
		<section id={"home"}>
			<div className="container">
				<div className="content">
					<h1>Let's turn our <span>youth</span> into an asset for the <span>future</span></h1>
					<h2>Youth's Horizon</h2>
					<div className="social">
						<a href="https://twitter.com/YouthsHorizon" target="_blank" className="fab fa-twitter"></a>
						<a href="https://www.instagram.com/youthshorizon/" target="_blank"
						   className="fab fa-instagram"></a>
						<a href="https://linkedin.com/company/youthshorizon" target="_blank"
						   className="fab fa-linkedin-in"></a>
						<a href="https://www.facebook.com/youthshorizon/" target="_blank"
						   className="fab fa-facebook"></a>
					</div>
				</div>
				<img className="down-arrow" src="https://yhstorage.blob.core.windows.net/website/dowArrow.svg" onClick={()=> window.location.href = "#projects"} />
			</div>
		</section>
	);
}

export default Home;
