import React from 'react';
import Rapports from "./rapports/rapports";
import "./projects.css";
import Travaux from "./travaux/travaux";

function Projects() {
	return (
		<section className="projects" id="projects">
			<div className="container">
				<h1 className="section_title">Nos <span>Rapports</span></h1>
				<div className="content">
					<Rapports/>
					<Travaux/>
				</div>
			</div>
		</section>
	);
}

export default Projects;