import React, {useEffect, useState} from 'react';
import {rapportServices} from "../../../services/rapportsService";
import RapportMap from "./rapportMap";

function Rapports(props) {
	const [rapports, setRapports] = useState([]);

	useEffect(async () => {
		const rapports = await rapportServices.fetch();
		console.log(rapports);
		setRapports(rapports.data);
	}, []);

	return (
		<div className={"all-works"}>
			<RapportMap rapports={rapports}/>
			<div className={"work"}>
				<img src={"https://yhstorage.blob.core.windows.net/website/img-1.jpg"} alt="rapport-image"
				     className={"work-img"}/>
				<div className="work-info">
					<h3>Rapport - à venir</h3>
					<h4>Notre prochain rapport arrive</h4>
					<p>Ils seront disponible dès leurs publications sur nos réseaux sociaux. Adhérez à Youth's Horizon
						pour avoir les rapports et tribunes en avant première et n'oubliez de nous suivre sur nos
						réseaux sociaux !</p>
				</div>
			</div>
		</div>
	);
}

export default Rapports;
