import React from 'react';
import "./contact.css";

function Contact(props) {
	return (
		<section id="contact">
			<div className="container">
				<h1 className="section_title conta">Contactez-<span>nous</span> ! </h1>
				<div className="content">
					<div className="card">
						<h2 className="cardTitle">Nos infos</h2>
						<div className="card-content">
							<h3 className="yh-name">Youth's Horizon</h3>
							<h4><i className="fas fa-map-pin"></i> 66 Avenue des Champs Elysées,
								75008 Paris,
								France </h4>
							<h3><i className="fas fa-envelope"></i> hello@youthshorizon.com</h3>
							<button className="btn btn--variant"
							        onClick={() => window.location.href = "mailto:hello@youthshorizon.com"}>Mail
							</button>

						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Contact;